import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://8384767ba0224cf6bc967baf4e50c6bc@sentry.io/1397783",
});

/* eslint-disable import/first */
import "es6-set/implement";
import "es6-map/implement";
import "raf/polyfill";
import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(<App />);
