import React, { useEffect, useState } from "react";

const maybeBoolToStr = (val) => {
  if (val === true) {
    return "true";
  } else if (val === false) {
    return "false";
  } else if (val === undefined) {
    return "undefined";
  } else {
    return "other";
  }
};

const App = () => {
  const [firstOK, setFirstOK] = useState(undefined);
  const [secondOK, setSecondOK] = useState(undefined);
  const [thirdOK, setThirdOK] = useState(undefined);
  const [fourthOK, setFourthOK] = useState(undefined);
  useEffect(() => {
    {
      const controller = new AbortController();
      controller.abort();
      fetch("https://httpstat.us/200", { signal: controller.signal })
        .then((res) => {
          setFirstOK(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            setFirstOK(true);
          } else {
            setFirstOK(false);
          }
        });
    }

    {
      const controller = new AbortController();
      fetch("https://httpstat.us/200?sleep=2000", { signal: controller.signal })
        .then((res) => {
          setSecondOK(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            setSecondOK(true);
          } else {
            setFirstOK(false);
          }
        });
      controller.abort();
    }

    {
      const controller = new AbortController();
      fetch("/", { signal: controller.signal })
        .then((res) => {
          setThirdOK(true);
        })
        .catch((err) => {
          setSecondOK(false);
        });
    }

    {
      const controller = new AbortController();
      fetch("https://httpstat.us/200", { signal: controller.signal })
        .then((res) => {
          setFourthOK(true);
        })
        .catch((err) => {
          setFourthOK(false);
        });
    }
  }, []);
  useEffect(() => {
    if (
      firstOK === true && secondOK === true && thirdOK === true &&
      fourthOK === true
    ) {
      document.body.style.background = "green";
    } else if (
      firstOK !== undefined && secondOK !== undefined &&
      thirdOK !== undefined && fourthOK !== undefined
    ) {
      document.body.style.background = "red";
    }
  }, [firstOK, secondOK, thirdOK, fourthOK]);
  return (
    <div>
      <div>first: {maybeBoolToStr(firstOK)}</div>
      <div>second: {maybeBoolToStr(secondOK)}</div>
      <div>third: {maybeBoolToStr(thirdOK)}</div>
      <div>fourth: {maybeBoolToStr(fourthOK)}</div>
      <h5>ua: {navigator.userAgent}</h5>
      <h5>
        Object.keys(new AbortController()):{" "}
        {"[" + Object.keys(new AbortController()).join(", ") + "]"}
      </h5>
      <h5>
        polyfill installed:{" "}
        {maybeBoolToStr(Object.keys(new AbortController()).length !== 0)}
      </h5>
    </div>
  );
};

export default App;
